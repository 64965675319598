// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities

// Components
import { ReactComponent as SvgArrowCircle } from 'src/images/arrow/arrow_circle.svg';
import { ReactComponent as SvgArrowArrow } from 'src/images/arrow/arrow_arrow.svg';

const ArrowComponent = ({
    direction = 'down',
    theme,
    animate = false,
    hover = false,
    style = css``,
    onClick = () => {},
}) => {
    // Global state
    const [focusedHeader] = useGlobal('focusedHeader');
    const [mobileNavigationActive] = useGlobal('mobileNavigationActive');

    // Direction
    const degrees = {
        up: 180,
        left: 90,
        right: -90,
        down: 0,
    };

    return (
        <Arrow
            {...{
                rotation: degrees[direction],
                theme,
                animate,
                hover,
                active: !focusedHeader && !mobileNavigationActive,
            }}
            onClick={() => onClick()}
            css={style}>
            <SvgArrowCircle />
            <SvgArrowArrow />
            {hover && <Circle {...{ theme }} />}
        </Arrow>
    );
};

const Circle = styled.div`
    background: ${props => props.theme.text()};
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;
`;

const ArrowAnimate = keyframes`
    0% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(0.08em);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
`;

const Arrow = styled.div`
    ${s.fonts.size(47)};
    width: 1em;
    height: 1em;
    position: relative;
    transform: rotate(${props => `${props.rotation}deg`});
    display: flex;
    justify-content: center;
    align-items: center;

    ${s.responsive.mq(30)} {
        ${props =>
            props.hover
                ? css`
                      cursor: pointer;
                      &:hover {
                          ${Circle} {
                              opacity: 1;
                          }

                          svg {
                              /* Arrow */
                              &:last-of-type {
                                  path {
                                      fill: ${props.theme.default()};
                                  }
                              }
                          }
                      }
                  `
                : ''};
    }

    svg {
        /* Circle */
        &:first-of-type {
            width: 1em;
            height: 1em;
        }

        /* Arrow */
        &:last-of-type {
            top: 0.21em;
            width: 0.5em;
            height: 0.5em;
            ${props =>
                props.animate
                    ? css`
                          animation-name: ${ArrowAnimate};
                          animation-duration: 2100ms;
                          animation-iteration-count: infinite;
                          animation-timing-function: ${s.easings.smooth.out};
                          animation-play-state: ${props.active
                              ? 'running'
                              : 'paused'};
                      `
                    : ''};
        }

        /* Common */
        position: absolute;

        path {
            fill: ${props => props.theme.text()};
        }
    }
`;

export default ArrowComponent;
